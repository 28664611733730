
<div col-md-10 class="wrapperGame">
  <div col-md-5 class="WhiteZone">
    <div class="blockText">
      <div class="heightBlockText">
        <p>"white" + "white" = "black"</p> 
        <p>Can you make the screen BLACK ?</p>       
      </div>
      <p class="titleGame">White Zone</p>
    </div>
    <div class="blockGame">

      <div class="blockStore">
        <div id="google-play-badge"><a href="https://play.google.com/store/apps/details?id=com.qmbsoft.WhiteZone" target="_blank"></a></div>
        <div id="app-store-badge"><a href="https://apps.apple.com/app/whitezone/id1580604708" target="_blank"></a></div>
      </div>

    </div>
  </div>
  <div col-md-5 class="ColorZone">
    <div class="blockText">
      <div class="heightBlockText">
        <p>Feel like an artist - mix RGB colors (red, green, blue) and create a colored shape.</p>
        <p>Do you think it's that easy? <br>Then follow the link</p>
      </div>
      <p class="titleGame">Color Zone</p>
    </div>
    <div class="blockGame">

      <div class="blockStore">
        <div id="google-play-badge"><a href="https://play.google.com/store/apps/details?id=com.qmbsoft.ColorZone" target="_blank"></a></div>
        <div id="app-store-badge"><a href=""></a></div>
      </div>

    </div>
  </div>

</div>
<div class="blockSupport">
  <div>Do you have any other questions?</div>
  <div>Write here: <span class="email">qmbsoft.app@gmail.com</span></div>
</div>


<div class="footer">

</div>
